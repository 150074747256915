<template>
	<form @submit.prevent="sendRequest" class="form__application">
		<or-input
			:placeholder="$t('general.name')"
			v-model="formData.name"
			icon-before="profile"
			class="search-panel__input main-type"
		/>
		<TelInput v-model="formData.phone" class="search-panel__input" />
		<or-button
			type="submit"
			class="authform__submit"
			aria-label="Button request"
		>
			{{ $t('web.callback_submit') }}
		</or-button>
	</form>
</template>

<script>
import { createRequest } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'
import TelInput from '@/components/TelInput.vue'

export default {
	name: 'FormApplication',
	components: { TelInput },
	data() {
		return {
			formData: {
				phone: '',
				name: '',
				type: 'developer'
			}
		}
	},
	methods: {
		sendRequest(evt) {
			const clone = Object.assign({}, this.formData)
			clone.phone = `${clone.phone.split(' ').join('')}`
			clone.url = document.URL
			createRequest(requestConfigs.POSTSendRequest, {
				jsonPayload: clone
			}).then(() => {
				evt.target.reset()

				this.$store.commit('showModal', {
					name: 'SuccessRequestModal'
				})
			})
		}
	}
}
</script>

<style lang="scss">
.form__application {
	display: flex;
	grid-gap: 10px;
	justify-content: center;
	padding: 30px;
	background-color: white;
	@media (max-width: 768px) {
		flex-direction: column;
	}

	& > label {
		width: 30%;

		@media (max-width: 768px) {
			width: 100%;
		}
	}

	& > button {
		width: 30%;

		@media (max-width: 768px) {
			width: 100%;
		}
	}

	.tel-input {
		@media (max-width: 768px) {
			max-width: 100%;
			width: 100%;
		}
	}

	.authform__submit {
		@media (max-width: 768px) {
			padding: 12px;
		}
	}
}
</style>
